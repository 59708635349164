<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col md="10" justify="center" align="center">
        <v-row>
          <v-col cols="12">
            <v-avatar color="secondary" size="200" >
              <v-img cover :src="photo" />
            </v-avatar>
          </v-col>

          <v-col cols="12">
            <VueQr :text="vCardData" :size="300" autoColor></VueQr>
          </v-col>
          <v-col cols="12">
            <v-img
              height="100px"
              class="ma-6"
              contain
              src="../assets/ngm.png"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueQr from "vue-qr";
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
export default {
  components: { VueQr },
  mounted() {
    this.get_employees();
    //
  },
  data() {
    return {
      vCardData: "",
      item: createItem(),
      photo: null,
    };
  },
  methods: {
    get_employees() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "employees",
        filters: [
          {
            field: "code",
            operator: "=",
            value: window.profile.code,
          },
        ],
      };
      console.log(qry);
      webserver("get_table", qry, (data) => {
        console.log(data);
        this.item = data[0];
        this.photo = this.getImageAgent(this.item.account,this.item.code, this.item.picture);
        this.vCardData = this.createVBC(data[0]);
      });
    },
    createVBC(id) {
  

      var vCard =
        "BEGIN:VCARD\r\n" +
        "VERSION:2.1\r\n" +
        "N:;QR4.Restaurant" +
        "\r\n" +
        "FN:" +
        id.name +
        "\r\n" +
        "ORG:NGM New Global Market Corporation\r\n" +
        "TEL;TYPE=CELL:" +
        id.mobile +
        "\r\n" +
        "ADR;CHARSET=UTF-8;TYPE=WORK:;;" +
        id.address +
        "\r\n" +
        "EMAIL;INTERNET;PREF:" +
        id.email +
        " \r\n" +
        "URL;type=WORK:" +
        "https://www.NewGlobalMarket.ca/" +
        "\r\n" +
        "NOTE:http://QR4.Restaurant/" +
        "\r\n" +
        "CATEGORIES:BUSINESS,WORK" +
        "\r\n" +
        "UID:" +
        id.code +
        "\r\n" +
        "REV:20110412165200\r\n" +
        "END:VCARD";

      return vCard;
    },
    getImageAgent: function (account, store,src) {
      var url = "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;
      return url;
    },
  },
};
</script>

<style>
</style>